// local
// export const domain = "http://127.0.0.1:8000/api/";
// export const domain = "http://192.168.31.181:8080/";
// dev
// export const domain = "https://staging-core.coach.in.th:8443/api/";
export const domain = "https://el-api.kisrasprint.com/api/";
// prod
// export const domain = "https://core.coach.in.th/api/";
// export const domain = "https://core.coach.in.th/api/";
// export const interest_set = parseFloat(0.07);


// export const domain_dashboard = "http://127.0.0.1:8000/cases/dashboard";
// export const domain_img_evidence = "http://127.0.0.1:8000";

// export const domain = "http://dip.ddc.moph.go.th/forensic_admin/public/api";


