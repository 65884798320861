<template>
  <div id="app">
    <router-view />
    <mainFooter />
  </div>
</template>
<script>
import mainFooter from "@/components/mainFooter";
export default {
  name: "App",
  components: {
    mainFooter,
  },
};
</script>

<style lang="scss"></style>
