import axios from "axios";
import { domain } from "../util/Constants";

const http = new axios.create({
  baseURL: domain,
});
const httpMethod = {
  get(url, options = {}) {
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    const localToken = vuex ? vuex.Auth.access_token : null;
    console.log("localToken on get", localToken);
    let accessToken = options.accessToken ? options.accessToken : localToken;
    let header = {
      Authorization: `Bearer ${accessToken}`,
    };
    return http
      .get(url, {
        ...options,
        headers: {
          ...header,
          ...options.headers,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log("get error", err);
      });
  },

  post(url, data = {}, options = {}) {
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    const localToken = vuex ? vuex.Auth.access_token : null;
    console.log("localToken on post", localToken);
    let accessToken = options.accessToken ? options.accessToken : localToken;
    let header = {
      Authorization: `Bearer ${accessToken}`,
    };
    return http
      .post(url, data, {
        ...options,
        headers: {
          ...header,
          ...options.headers,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  },
};

export default httpMethod;
