<template>
  <div class="" id="adCouse">
    <b-row class="justify-content-center">
      <b-col lg="12" sm="12" cols="12" class="Carousel">
        <VueSlickCarousel  v-bind="settings" :arrows="true" :dots="false">
<!--        (width*3.2)/10 -->
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
            <img :src="img" width="480px" height="auto" style="margin-left: 30px;margin-right: 200px">
        </VueSlickCarousel>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import img from '/public/temp2.jpeg';
export default {
  name: 'adCouse',
  data() {
    return {
      img:img,
      settings: {
        "focusOnSelect": false,
        "arrows": true,
        "infinite": true,
        "initialSlide": 2,
        "speed": 500,
        "slidesToShow":3,
        "slidesToScroll": 1,
        "swipeToSlide": true,
        "autoplay": true,
        "ltr": true,
        "autoplaySpeed": 3000,
        "cssEase": "linear",
        "responsive": [
          {
            "breakpoint": 2560,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll":1 ,
              // "centerMode": true,
              "centerPadding": '40%',
              "initialSlide": 1
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll":1 ,
              "centerMode": true,
              "centerPadding": '20%',
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll":1 ,
              "centerMode": true,
              "centerPadding": '20%',
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              // "centerMode": true,
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          },
          {
            "breakpoint": 375,
            "settings": {
              // "centerMode": true,
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
    }
  },
  components: { VueSlickCarousel },
}
</script>
<style lang="scss">
#adCouse{
  .VueSlickCarousel,div{
    padding: 0;
    /*border-radius: 20px;*/
  }
  .slick-arrow.slick-next, .slick-arrow.slick-prev{
    z-index: 999;
    color: #000000;
    border-color: #000000;
    background-color: transparent;
    margin-left: 50px;
    margin-right: 50px;
  }
}


</style>