import Service from "../service";
import authService from "@/service/authService";

const auth = {
  onRegister(data) {
    return Service.post("/auth/register", data);
  },
  onLogin(data) {
    return Service.post("/auth/login", data);
  },
  onLogout() {
    return authService.post("/auth/logout");
  },
  onLoginGoogle(data) {
    return Service.post("/auth/google", data);
  },
  onLoginFacebook(data) {
    return Service.post("/auth/facebook", data);
  },
};

export default auth;
