import Service from "../../service/index";
// import BaseService from "../../service/service";

const User = {
  name: "User",
  stateFactory: true,
  namespaced: true,
  state: {
    userData: [],
    user_login: null,
    access_token: null,
    token_type: null,
    username: null,
  },
  mutations: {
    SET_USER: (state, data) => {
      state.userData = data;
    },
    SET_TOKEN(state, res) {
      state.access_token = res.access_token;
      state.token_type = res.token_type;
      state.userData = res.user;
      state.username = res.user.first_name;
      console.log("access_token", res.access_token);
      console.log("token_type", res.token_type);
      console.log("users", res.user);
      console.log("username", res.user.first_name);
    },
    CLEAR_TOKEN(state, res) {
      state.access_token = null;
      state.token_type = null;
      state.userData = null;
      state.username = null;
      console.log("access_tokenLogout", res.access_token);
      console.log("token_typeLogout", res.token_type);
      console.log("usersLogout", res.user);
    },
    CLEAR_COURSE: (state, data) => {
      state.course = data;
    },
  },
  actions: {
    register({ commit }, data) {
      return Service.auth.onRegister(data).then((response) => {
        if (response.code === 200) {
          console.log(commit);
          // commit("SET_USER", response.result);
          return response;
        }
      });
    },
    login({ commit }, data) {
      return Service.auth.onLogin(data).then((response) => {
        if (response.code === 200) {
          console.log("response.result", response.result);
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    loginGoogle({ commit }, data) {
      return Service.auth.onLoginGoogle({ token: data }).then((response) => {
        if (response.code === 200) {
          console.log("response.result", response.result);
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    loginFacebook({ commit }, data) {
      return Service.auth.onLoginFacebook(data).then((response) => {
        if (response.code === 200) {
          console.log("response.result", response.result);
          commit("SET_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
    logout({ commit }) {
      return Service.auth.onLogout().then((response) => {
        if (response.code === 200) {
          commit("CLEAR_TOKEN", response.result);
          return response;
        } else {
          return response.code;
        }
      });
    },
  },
};

export default User;
