<template>
  <div id="search" class="ml-0 my-3">
    <b-input-group size="sm" id="search">
      <b-form-input
        size="sm"
        id="input_search"
        type="search"
        placeholder="ค้นหาคอร์ส..."
        v-model="searchText"
        shadow="none"
        style="border-radius: 30px"
      ></b-form-input>
      <b-input-group-prepend>
        <b-button
          pill
          class="btn btn-light search_btn btnHover"
          size="sm"
          type="button"
          @click="search()"
        >
          <b-icon-search></b-icon-search>
        </b-button>

        <!--        <b><b-icon-search></b-icon-search></b>-->
      </b-input-group-prepend>
    </b-input-group>
  </div>
</template>
<script>
export default {
  name: "Search",
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    search() {
      alert(this.searchText);
    },
  },
};
</script>
<style>
.search_btn {
  background-color: transparent !important;
  border: none;
  color: black;
}
input#input_search.form-control.form-control-sm:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: #de7033 2px solid !important;
}
/*@media screen and (min-width: 993px) {*/
/*  #input_search {*/
/*    width: 300px;*/
/*  }*/
/*}*/
/*@media screen and (max-width: 991px) {*/
/*  #search {*/
/*    display: none;*/
/*  }*/
/*}*/
</style>
