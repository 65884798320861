import Service from "../service";

const courses = {
  onGetCourses() {
    return Service.get("/courses");
  },
  onGetCourse(id) {
    console.log("id", id);
    return Service.get(
      `/courses?id=${id}&with=lessons|lessons.child_lessons|attach|enroll|creator`
    );
  },
  onGetAllCourses() {
    return Service.get("/courses?with=lessons|attach|enroll|creator");
  },
  onGetQuestion() {
    return Service.get("/questions?with=answers|attach");
  },
};

export default courses;
