<template>
  <div id="allTeacher" class="teacher mt-5">
    <p
      style="font-weight: bold; font-size: 24px"
      class="my-lg-2 my-md-2 my-sm-2 my-0 mx-1"
    >
      ผู้สอน
    </p>
    <VueSlickCarousel
      v-if="isShowCreator"
      v-bind="settings"
      :arrows="true"
      :dots="true"
    >
      <span v-for="creator in dataCreator" :key="creator.id">
        <b-card
          no-body
          class="overflow-hidden mb-2 mx-1 card-article"
          style="max-width: 450px; border-radius: 30px"
        >
          <b-row no-gutters>
            <b-col md="4">
              <b-avatar :src="img" size="8rem" class="mx-3 my-3"></b-avatar>
            </b-col>
            <b-col md="8" class="pl-2">
              <b-card-body
                :title="creator.first_name + ' ' + creator.last_name"
              >
                <b-card-text class="mx-3">{{
                  creator.first_name + " " + creator.last_name
                }}</b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </span>
    </VueSlickCarousel>
  </div>
</template>
<script>
import img from "./../../public/temp2.jpeg";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Creator",
  props: {
    dataCreator: null,
  },

  data() {
    return {
      isShowCreator: false,
      img: img,
      settings: {
        focusOnSelect: false,
        arrows: true,
        // "infinite": true,
        // "initialSlide": 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        // "autoplay": true,
        ltr: true,
        // "autoplaySpeed": 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              // "centerMode": true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 375,
            settings: {
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  created() {
    if (this.dataCreator) {
      return (this.isShowCreator = true);
    }
    // await this.getCourses();
  },
  components: { VueSlickCarousel },
  methods: {
    // async getCourses() {
    //   const coursesResponse = await Service.Courses.onGetCourses();
    //   if (coursesResponse.code === 200) {
    //     const result = coursesResponse.result;
    //     const courses = result.courses.data;
    //     this.data = courses;
    //     this.isShowCourses = true;
    //   }
    // },
    // async goCourse(data) {
    //   const data_course = data;
    //   console.log("data_course", data_course);
    //   const path = `/courses?id=` + data_course.id;
    //   await this.$router.push(path);
    // },
  },
};
</script>
<style lang="scss">
#allTeacher {
  margin-left: 5%;
  margin-right: 5%;
  .card-article {
    max-height: 750px;
    overflow: hidden;
    border-radius: 15px;
  }
}
</style>
