import Vue from "vue";
import Vuex from "vuex";
import Courses from "./modules/CoursesModule";
import Auth from "./modules/AuthModule";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const modules = {
  Courses: Courses,
  Auth: Auth,
};
export default new Vuex.Store({
  modules,
  plugins: [createPersistedState()],
});
