<template>
  <div id="sideMenuMobile">
    <div v-b-toggle.sidebar-1><b-icon-list-ul></b-icon-list-ul>หมวดหมู่</div>
    <b-sidebar id="sidebar-1" class="" title="หมวดหมู่" shadow>
      <div class="px-3 py-2">
        <a>ถ่ายภาพ/กราฟฟิก/ออกแบบ</a>
        <hr />
        <a>หน้า3</a>
        <hr />
        <a>หน้า4</a>
        <hr />
      </div>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  name: "sideMenuMobile",
  data() {
    return {};
  },
};
</script>
<style>
/*@media screen and (min-width: 992px) {*/
/*  #sideMenuMobile {*/
/*    display: none !important;*/
/*  }*/
/*}*/
</style>
