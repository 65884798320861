<template>
  <div id="listCourses" class="mt-5">
    <p
      style="font-weight: bold; font-size: 20px"
      class="my-lg-2 my-md-2 my-sm-2 my-0 mx-1"
    >
      คอร์สเรียน
    </p>
    <b-row>
      <b-col
        lg="3"
        md="12"
        sm="12"
        cols="12"
        v-for="data in dataAll"
        :key="data.id"
      >
        <b-card
          border-variant="secondary"
          :title="dataAll.name"
          :img-src="img"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 mx-1 card-article"
          @click="goCourse(data)"
          style="border-radius: 15px"
        >
          <b-card-text
            class="short-desc"
            v-html="data.description"
          ></b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import img from "./../../public/temp2.jpeg";
// import Service from "@/service";
export default {
  name: "listCourses",
  props: {
    data: null,
    dataAll: null,
  },
  data() {
    return {
      // data: [],
      // dataAll: [],
      isShowCourses: false,
      img: img,
      settings: {
        focusOnSelect: false,
        arrows: true,
        // "infinite": true,
        // "initialSlide": 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        // "autoplay": true,
        ltr: true,
        // "autoplaySpeed": 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              // "centerMode": true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 375,
            settings: {
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  // async created() {
  //   await this.getAllCourses();
  //   await this.getCourses();
  // },
  components: {},
  methods: {
    // async getAllCourses() {
    //   const coursesResponse = await Service.Courses.onGetAllCourses();
    //   if (coursesResponse.code === 200) {
    //     const result = coursesResponse.result;
    //     const courses = result.courses.data;
    //     this.dataAll = courses;
    //     this.isShowCourses = true;
    //   }
    // },
    // async getCourses() {
    //   const coursesResponse = await Service.Courses.onGetCourses();
    //   if (coursesResponse.code === 200) {
    //     const result = coursesResponse.result;
    //     const courses = result.courses.data;
    //     this.data = courses;
    //     this.isShowCourses = true;
    //   }
    // },
    async goCourse(data) {
      const data_course = data;
      console.log("data_course", data_course);
      const path = `/courses?id=` + data_course.id;
      await this.$router.push(path);
    },
  },
};
</script>
<style lang="scss">
#listCourses {
  margin-left: 5%;
  margin-right: 5%;
  img.card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
</style>
