import Service from "../../service/index";
// import BaseService from "../../service/service";

const Courses = {
  name: "Courses",
  stateFactory: true,
  namespaced: true,
  state: {
    course: [],
    allCourses: [],
  },
  mutations: {
    SET_COURSE: (state, data) => {
      state.course = data;
    },
    SET_ALLCOURSE: (state, data) => {
      state.allCourses = data;
    },
    CLEAR_COURSE: (state, data) => {
      state.course = data;
    },
  },
  actions: {
    setCourse({ commit }, data) {
      console.log("SET_COURSE", data);
      commit("SET_COURSE", data);
      return data;
    },
    clearCourse({ commit }, data) {
      data = [];
      console.log("CLEAR_COURSE!!!!");
      commit("CLEAR_COURSE", data);
      return data;
    },
    getCourse({ commit }, id) {
      return Service.Courses.onGetCourse(id).then((response) => {
        if (response.code === 200) {
          let response_data = response.result.courses.data[0];
          commit("SET_COURSE", response_data);
          return response;
        }
      });
    },
    getAllCourse({ commit }) {
      return Service.Courses.onGetAllCourses().then((response) => {
        if (response.code === 200) {
          let response_data = response.result.courses.data[0];
          commit("SET_ALLCOURSE", response_data);
          return response;
        }
      });
    },
  },
};

export default Courses;
