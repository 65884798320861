<template>
  <div class="home">
    <topNav />
    <b-row>
      <b-col lg="2">
        <!--        <sideMenuMobile />-->
      </b-col>
    </b-row>
    <adsCouse />
    <b-row>
      {{ this.userData }}
      <b-col lg="2">
        <sideMenu />
      </b-col>
      <b-col lg="10">
        <allTeacher :dataCreator="[this.dataCreator]" />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="10" offset-lg="2">
        <listCourses :dataAll="this.data" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import listCourses from "@/components/listCourses.vue";
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
import adsCouse from "@/components/adsCouse.vue";
import sideMenu from "@/components/sideMenu.vue";
import allTeacher from "@/components/allTeacher.vue";
import Service from "@/service";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      data: null,
      dataCreator: null,
    };
  },
  created: {
    ...mapState({
      userData: (state) => {
        return state.Auth.userData;
      },
    }),
  },
  async mounted() {
    await this.getAllCourses();
  },
  components: {
    listCourses,
    topNav,
    // sideMenuMobile,
    adsCouse,
    sideMenu,
    allTeacher,
  },

  methods: {
    async getAllCourses() {
      const coursesResponse = await Service.Courses.onGetAllCourses();
      if (coursesResponse.code === 200) {
        const result = coursesResponse.result;
        const courses = result.courses.data;
        this.data = courses;
        this.dataCreator = this.data[0].creator;
        // this.isShowCourses = true;
      }
    },
  },
};
</script>
<style>
.home {
  height: auto;
  min-height: 100vh;
}
</style>
